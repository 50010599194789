import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import MainSearch from "../../components/main-search/main-search"
import localize from "../../components/localize"

const Content = ({ bonuses, locale, filters }) => {
  return (
    <div>
      <MainSearch bonuses={bonuses} locale={locale} headerFilters={filters} />
    </div>
  )
}

const Bingo = ({ location, data, pageContext }) => {
  let site = useContext(ThemeContext)

  let { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => x.hasBingo)

  const pageData = data.sanityGenericLandingPage
  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} bonuses={bonuses}
        title={pageData && pageData.seoTitle}
         alternateSlug={alternateLocaleSlugs}
        description={pageData && pageData.seoMeta}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} locale={locale} filters={pageData.headingFilters} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(Bingo)
export const pageQuery = graphql`
  query BingoLanding {
    sanitySilo (_id: {eq: "c67d7021-8d59-4ffd-802c-b9b51a16d880"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "has-bingo" }
    ) {
      ...GenericLanding
    }
  }
`
